<script setup>
import { usePlaningStore } from '@/stores/planning';
import { Link } from '@inertiajs/vue3';
import { computed, inject } from 'vue';
import DateLabel from './DateLabel.vue';

const props = defineProps({
    date: {},
    open: {},
    total: {},
    selected: { type: Boolean, default: false },
});

const store = usePlaningStore();
const dayjs = inject('dayjs');
const route = inject('route');

const today = dayjs();

const dateIsBeforeMonth = computed(() => props.date.isBefore(store.startOfMonth));
const dateIsAfterMonth = computed(() => props.date.isAfter(store.endOfMonth));
</script>
<template>
    <template v-if="!dateIsBeforeMonth && !dateIsAfterMonth">
        <Link
            :only="['query', 'shifts']"
            :class="{ 'bg-blue/10': selected }"
            :href="route('location.planning.index', { date: date.format('DD-MM-YYYY') })"
            class="relative flex flex-col items-center justify-between p-2 overflow-hidden bg-white border border-white rounded-lg cursor-pointer hover:border-blue/10 hover:border"
        >
            <span class="font-semibold text-[24px] text-blue">{{ date.format('D') }}</span>

            <DateLabel :open="open" :total="total" />
        </Link>
    </template>
    <template v-else>
        <div
            :only="['query', 'shifts']"
            :class="{ 'bg-blue/10': selected }"
            class="relative flex flex-col items-center justify-between p-2 overflow-hidden bg-white border border-white rounded-lg opacity-10 hover:border-blue/10 hover:border"
        >
            <span class="font-semibold text-[24px] text-blue">{{ date.format('D') }}</span>
            <DateLabel :open="open" :total="total" />
        </div>
    </template>
</template>
