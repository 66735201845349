import { defineStore } from 'pinia';
import dayjs from 'dayjs';

export const usePlaningStore = defineStore('planning', {
    state: () => ({
        selectedDateString: '',
        shiftsByDate: {},
        shiftCountsByDate: {},
    }),
    getters: {
        date: (state) => dayjs(state.selectedDateString),
        startOfMonth: (state) => state.date.startOf('month'),
        endOfMonth: (state) => state.date.endOf('month'),
        startOfTheCal: (state) => state.date.startOf('month').startOf('week'),
        endOfTheCal: (state) => state.date.endOf('month').endOf('week'),
        calDates: (state) =>
            getDatesBetween(state.startOfTheCal, state.endOfTheCal),
        calDatesPerWeek: (state) =>
            state.calDates.reduce((all, one, i) => {
                const ch = Math.floor(i / 7);
                all[ch] = [].concat(all[ch] || [], one);
                return all;
            }, []),
    },
    actions: {
        load(obj) {
            this.selectedDateString = obj.date;
            this.shiftsByDate = obj.shiftsByDate;
            this.shiftCountsByDate = obj.shiftCountsByDate;
        },
        setRoute(name, url) {
            this.previousRoutes[name] = url;
            if (this.history[0] !== url) {
                this.history.unshift(url);
            }
        },
    },
});

function getDatesBetween(start, end, step = 'day') {
    let localStart = start;
    let dates = [];
    while (localStart.isBefore(end)) {
        dates.push(localStart);
        localStart = localStart.add(1, step);
    }
    return dates;
}
